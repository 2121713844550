import React from 'react'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import useQueryParams from 'components/common/useQueryParams/useQueryParams'
const Heading = ({title, buttons = []}) => {
  const location = useNavigate()
  const queryParams = useQueryParams()
  const handleClick = (pathOrFunction, text) => {
    if (typeof pathOrFunction === 'string') {
      let path
      if(text === 'Back'){
        path = queryParams?.redirect ? queryParams.redirect : pathOrFunction
      } else path = pathOrFunction

      if(text === 'Show Composer'){
        window.location = path
      } else location(path)
      location(path)
    } else if (typeof pathOrFunction === 'function') {
      pathOrFunction()
    }
  }
  return (
    <div className='sinch-row'>
      <h1>{title}</h1>
      <div className='button-group'>
        {buttons.map((button, index) => (
          <Button
            key={index}
            disabled={button.disabled ? button.disabled : false}
            className={`button ${ button.classes ? button.classes : ''}`}
            onClick={() => handleClick(button.onClick, button.buttonText)}
          >
            {button.buttonText}
          </Button>
        ))}
      </div>
    </div>
  )
}

export default Heading