const RequestsEndpoint = (axios) => {
  const search = async (ids) => {
    const { data } = await axios.get(`/web/search?search=${ids}`)
    return data
  }

  const getRequests = async ({
    template_id,
    page,
    query,
    sortBy,
    sortDirection,
    states,
    pageSize,
  }) => {
    const { data } = await axios.get(`/web/templates/${template_id}/requests?active_requests=true&page=${page}&query=${query}&sortBy=${sortBy}&sortDirection=${sortDirection}&states=${states}&pageSize=${pageSize}`)
    return data
  }

  const getRequest = async (id) => {
    const { data } = await axios.get(`/web/requests/${id}`)
    return data
  }

  const getTestRequests = async (template_id) => {
    const { data } = await axios.get(`/web/templates/${template_id}/requests`)
    return data
  }

  const createRequest = async (template_id, params) => {
    const { data } = await axios.post(`/web/templates/${template_id}/requests`, params)
    return data
  }

  const restartRequest = async (id) => {
    const { data } = await axios.get(`/web/requests/${id}/restart`)
    return data
  }
  return {
    createRequest,
    getTestRequests,
    getRequest,
    getRequests,
    restartRequest,
    search,
  }
}

export default RequestsEndpoint